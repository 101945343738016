.footer-clean {
    margin: 50px 0;
    background-color: #e7f1ff;
    color: #4b4c4d;
    box-shadow: 0 50vh 0 50vh #e7f1ff;
}
    .footer-clean .item {
        padding-bottom: 20px;
    }

    .footer-clean h3 {
        margin-top: 0;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 16px;
    }

    .footer-clean ul {
        padding: 0;
        list-style: none;
        line-height: 1.6;
        font-size: 14px;
        margin-bottom: 0;
    }

        .footer-clean ul a {
            color: inherit;
            opacity: 0.8;
        }

            .footer-clean ul a:hover {
                opacity: 1;
            }

    .footer-clean .item.social {
        text-align: right;
    }

@media (max-width:767px) {
    .footer-clean .item {
        text-align: center;
        padding-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .footer-clean .item.social {
        text-align: center;
    }
}

.footer-clean .item.social > a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-left: 10px;
    margin-top: 22px;
    color: inherit;
    opacity: 0.75;
}

    .footer-clean .item.social > a:hover {
        opacity: 0.9;
    }

@media (max-width:991px) {
    .footer-clean .item.social > a {
        margin-top: 40px;
    }
}

@media (max-width:767px) {
    .footer-clean .item.social > a {
        margin-top: 10px;
    }
}

.footer-top {
    box-shadow: 0 -.25rem -.75rem rgba(0, 0, 0, .05);
}

.left {
    float: left;
    padding: 10px;
}

.right {
    float: right;
    padding: 10px;
}

.footer-clean .copyright {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 13px;
    opacity: 0.6;
}
