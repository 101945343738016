
/* ********************************* */
#logoCity:hover{
    cursor:pointer;
}

#logoCity, #logo311 {
    height: 75px;
}

@media only screen and (max-width: 992px) {
    #logoCity, #logo311 {
        height: calc( 6vw);
        top: 35px;
    }
}

@media only screen and (max-width: 768px) {
    #logoCity, #logo311 {
        height: calc( 6vw);
        top: 30px;
    }
    .list-group-flush > .list-group-item:last-child {
        border-bottom-width: 1px;
    }
    .ExpServCat {
        align-items: end;
    }
    #cheader {
    margin-left: 5px;
    }
}


#cheader {
    font-weight: 600;
}

.border_bottom_darkblue {
    border-bottom: 1px solid #00457c;
    border-bottom-width: 4px;
}

.display-5, .display-6 {
    font-weight: 600 !important;
}
/*
.list-group-item {
    font-size: 1.25rem !important;
}

.accordion-button {
    font-size: 1.4rem !important;
}

.MuiTypography-body1 {
    font-size: 1.25rem !important;
}

.display-2, .display-5, .display-6 {
    font-weight: 600 !important;
}

.accordion-body a {
    padding-bottom: 1rem !important;
    font-size: 1.25rem !important;
}
*/

/* ********************************** */
.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.accordion-body a {
    text-decoration: none;
}

    .accordion-body a:hover {
        text-decoration: underline;
    }

.accordion-header i {
    padding-right: 10px;
}


body {
    font-family: 'ProximaNovaRgBold','Helvetica',sans-serif;
}

.cagbg_header {
    background-color: #25b1dd;
    color: white;
}

/*.list-group.list-group-flush  a {*/
    /*font-weight: 700;*/
    /*color: #00457c;
    white-space: nowrap;
    max-width: 385px;
    overflow-x: hidden;
}*/

.list-group.list-group-flush a {
    color: #00457c;
    text-align: right;
    align-items: center;
}


#csr_qlinks i, .accordion-body .ms-2 {
    color: #fb7120
}

#accordionServices a {
    /*font-weight: 700;*/
    color: #00457c;
}

#accordionServices i {
    color: #fb7120
}

.headerTitle {
    font-weight: bold;
}

.modeLinks i {
    display: block;
}

.modeLinks p {
    text-align: center;
    margin-top: 20px;
}

.modeLinks a {
    text-decoration: none;
    color: inherit;
    border: 1px solid lightgray;
    border-bottom-width: 4px;
}

    .modeLinks a:hover {
        background: #20a2cb;
    }

#modeLinks_app {
    border-bottom-color: #fb7120
}

#modeLinks_call {
    border-bottom-color: #c4ce37
}

#modeLinks_info {
    border-bottom-color: darkblue
}

#modeLinks_website {
    border-bottom-color: #00aeef;
    cursor: pointer;
}
#modeLinks_collection {
    border-bottom-color: #96352e;
}
#modeLinks_yardwaste {
    border-bottom-color: #269b17;
    cursor: pointer;
}

#modeLinks_emailps {
    border-bottom-color: #CFDB5D
}

.cagbg_header.cardsContainer {
    color: black;
}

.cagSearchServices {
    background-color: #f5f5f5
}

#csr_qlinks div.me-2:hover {
    text-decoration: underline;
}

.border_bottom_orange {
    border-bottom: 1px solid #fb7120;
    border-bottom-width: 4px;
}

.border_bottom_blue {
    border-bottom: 1px solid #00aeef;
    border-bottom-width: 4px;
}

select#CouncilSelection {
    min-height: 200px;
    overflow: auto;
}

div[aria-labelledby='simple-modal-title'] {
    overflow: auto;
}


.paper {
    padding: 5px;
    text-align: left;
    color: darkgrey;
    margin-bottom: 5px;
    background-color: grey;
}


.altpaper {
    padding: 5px;
    text-align: left;
    color: lightgray;
    margin-bottom: 5px;
}

.editableContainer .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
    display: none;
}

    .editableContainer .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item:nth-child(2) {
        display: block;
    }

.editableContainer .MuiGrid-root.MuiGrid-container {
    background: whitesmoke;
}

.editableContainer .MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 0px;
    border: 2px solid whitesmoke;
}

.editableContainer .MuiContainer-root > .MuiBox-root {
    padding: 10px;
}
